import { BlankLayoutV2 } from "@/layouts/BlankLayout";
import { cache, createClient, persistor } from "@/lib/apollo/client";
import { auth } from "@/lib/auth/auth";
import { AuthReadyProvider } from "@/lib/auth/useAuthReady";
import { UserProvider } from "@/lib/auth/useUser";
import FlagProvider from "@/lib/unleash";
import { ApolloProvider, gql } from "@apollo/client";
import { PrismicPreview } from "@prismicio/next";
import { captureException, setUser } from "@sentry/nextjs";
import { onAuthStateChanged } from "firebase/auth";
import Head from "next/head";
import Script from "next/script";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import "@/styles/global.css";
// biome-ignore lint/correctness/noUndeclaredDependencies: It's a weird import
import "mapbox-gl/dist/mapbox-gl.css";
import "react-toastify/dist/ReactToastify.css";

onAuthStateChanged(auth, (user) => {
    if (user) {
        setUser({ id: user.uid, email: user.email });
    } else {
        setUser(null);
    }
});

cache.writeQuery({
    query: gql`
        query getCartItems {
            cartItems @client {
                uid
                tenantId
            }
        }
    `,
    data: { cartItems: [] },
});

const client = createClient(cache);

const EnjinKonsol = ({ Component, pageProps, err }) => {
    const Layout = Component.Layout || BlankLayoutV2;

    useEffect(() => {
        const init = async () => {
            try {
                persistor?.restore();
            } catch (error) {
                const customError = new Error(error.message);
                customError.name = "ApolloStoreError";
                captureException(customError, (ctx) =>
                    ctx.setExtras({
                        error: error,
                    }),
                );
            }
        };

        if (typeof window !== "undefined") {
            init();
        }
    }, []);

    return (
        <AuthReadyProvider>
            <Head>
                <meta
                    key="enjin-version"
                    property="enjin-version"
                    content={`enjin-konsol/${
                        process.env.NEXT_PUBLIC_ENJIN_VERSION || "unknown"
                    }`}
                />
                {process.env.DISABLE_INDEXING ? (
                    <meta name="robots" content="noindex, nofollow" />
                ) : null}
            </Head>

            <Script
                src="https://static.cloudflareinsights.com/beacon.min.js"
                data-cf-beacon='{"token": "c329a1da4464478caf12e270e41c70b0"}'
            />

            <UserProvider>
                <ApolloProvider client={client}>
                    <FlagProvider>
                        <PrismicPreview repositoryName="courtsite-konsol">
                            <Layout>
                                {/* `err` is a workaround for https://github.com/vercel/next.js/issues/8592 */}
                                <Component {...pageProps} err={err} />
                                <ToastContainer
                                    hideProgressBar
                                    position="top-center"
                                />
                            </Layout>
                        </PrismicPreview>
                    </FlagProvider>
                </ApolloProvider>
            </UserProvider>
        </AuthReadyProvider>
    );
};

export default EnjinKonsol;
