import { auth } from "@/lib/auth/auth";
import {
    type ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

const AuthReadyContext = createContext(false);

export const useAuthReady = () => useContext(AuthReadyContext);

export const AuthReadyProvider = (props: { children?: ReactNode }) => {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        auth.authStateReady().then(() => setReady(true));
    }, []);
    return (
        <AuthReadyContext.Provider value={ready}>
            {props.children}
        </AuthReadyContext.Provider>
    );
};
