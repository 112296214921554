import { auth } from "@/lib/auth/auth";
import type { User } from "firebase/auth";
import {
    type ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

const UserContext = createContext<User | null>(null);

export const useUser = (): User | null => useContext(UserContext);

export const UserProvider = (props: { children?: ReactNode }): JSX.Element => {
    const [user, setUser] = useState<User | null>(null);
    useEffect(() => {
        auth.onAuthStateChanged(setUser);
    }, []);
    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    );
};
